var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      [
        _c(
          "v-layout",
          { staticClass: "my-auto px-4" },
          [
            _vm.payload.showScanCode
              ? _c("barcode-scanner", {
                  attrs: {
                    module: _vm.payload.module,
                    search: _vm.search,
                    scannerPayload: _vm.scannerPayload,
                    disabled: !_vm.checkActionPermission(
                      _vm.payload.module,
                      _vm.CONSTANTS.EDIT
                    ),
                    loading: _vm.scannerFieldLoading,
                  },
                  model: {
                    value: _vm.search,
                    callback: function ($$v) {
                      _vm.search = $$v
                    },
                    expression: "search",
                  },
                })
              : _c("v-text-field", {
                  staticClass: "pa-0",
                  attrs: {
                    solo: "",
                    label: _vm.$t("message.common.search"),
                    "hide-details": "",
                    dense: "",
                  },
                  model: {
                    value: _vm.search,
                    callback: function ($$v) {
                      _vm.search = $$v
                    },
                    expression: "search",
                  },
                }),
          ],
          1
        ),
        _c(
          "v-card-title",
          { staticClass: "pa-0 pl-1 mt-1" },
          [
            _vm.payload.addBtnTo &&
            _vm.checkActionPermission(_vm.payload.module, _vm.CONSTANTS.CREATE)
              ? _c(
                  "v-btn",
                  {
                    staticClass: "ml-3",
                    attrs: {
                      color: "primary",
                      fab: "",
                      "x-small": "",
                      id: "newbtnTo",
                      to: _vm.payload.addBtnTo,
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-plus")])],
                  1
                )
              : _vm._e(),
            _c("v-spacer"),
            _c("h4", [
              _vm._v(
                _vm._s(
                  _vm.payload.isCustomModules
                    ? _vm.payload.moduleTitle
                    : _vm.$t(_vm.payload.moduleTitle)
                )
              ),
            ]),
            _c("v-spacer"),
            _c("h6", { staticClass: "mr-4" }, [
              _vm._v(
                _vm._s(_vm.payload.list.length) +
                  " " +
                  _vm._s(_vm.$t("message.common.of")) +
                  " " +
                  _vm._s(_vm.payload.totalCount)
              ),
            ]),
          ],
          1
        ),
      ],
      _c(
        "v-card-text",
        { staticClass: "mt-2 mb-4" },
        [
          (_vm.payload.itemHeaders &&
            _vm.payload.list &&
            _vm.payload.list.length &&
            _vm.$vuetify.breakpoint.name == "sm") ||
          _vm.$vuetify.breakpoint.name == "md" ||
          _vm.$vuetify.breakpoint.name == "lg" ||
          _vm.$vuetify.breakpoint.name == "xl"
            ? _c(
                "v-card",
                {
                  staticClass: "pa-0 mt-0",
                  attrs: { outlined: "", color: "#e8eef0" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _vm._l(_vm.payload.itemHeaders, function (header, index) {
                        return [
                          _c(
                            "v-col",
                            {
                              key: index,
                              class: _vm.payload.items[index].class,
                              attrs: { cols: _vm.payload.items[index].cols },
                            },
                            [
                              header === "message.projects.drawingGroup" &&
                              _vm.payload.advanceDrawingTypes
                                ? _c("strong", [_vm._v(_vm._s(_vm.$t(header)))])
                                : header !== "message.projects.drawingGroup"
                                ? _c("strong", [_vm._v(_vm._s(_vm.$t(header)))])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("v-virtual-scroll", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.payload.list.length,
                expression: "payload.list.length",
              },
            ],
            attrs: {
              items: _vm.payload.list,
              "item-height": _vm.getItemHeight(_vm.id),
              "max-height": "95vh",
              id: _vm.id,
            },
            scopedSlots: _vm._u(
              [
                _vm.$vuetify.breakpoint.name == "sm" ||
                _vm.$vuetify.breakpoint.name == "md" ||
                _vm.$vuetify.breakpoint.name == "lg" ||
                _vm.$vuetify.breakpoint.name == "xl"
                  ? {
                      key: "default",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-card",
                            {
                              staticClass: "pa-1 mt-1",
                              staticStyle: { overflow: "hidden" },
                              attrs: {
                                "min-height": _vm.$vuetify.breakpoint.xs
                                  ? "100"
                                  : "55",
                                "max-height": _vm.$vuetify.breakpoint.xs
                                  ? "150"
                                  : "110",
                                outlined: "",
                                color: "#e8eaf6",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.checkActionPermission(
                                    _vm.payload.module,
                                    _vm.CONSTANTS.EDIT
                                  )
                                    ? _vm.payload.editHandler(item)
                                    : ""
                                },
                              },
                            },
                            [
                              _c(
                                "v-card-text",
                                {
                                  staticClass: "pa-1",
                                  class:
                                    _vm.$t(_vm.payload.moduleTitle) ===
                                      _vm.$t("message.layout.drawing") &&
                                    item &&
                                    item.is_completed === 5
                                      ? "orange--text text--lighten-2"
                                      : "",
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      [
                                        _vm._l(
                                          _vm.payload.items,
                                          function (colValues, index) {
                                            return [
                                              colValues.value
                                                ? [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        key: index,
                                                        class: colValues.class,
                                                        attrs: {
                                                          cols: colValues.cols,
                                                        },
                                                      },
                                                      [
                                                        colValues.isParagraph
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "custom-height",
                                                                class:
                                                                  item[
                                                                    colValues
                                                                      .value
                                                                  ] &&
                                                                  colValues.value ==
                                                                    "description" &&
                                                                  item[
                                                                    colValues
                                                                      .value
                                                                  ].length > 100
                                                                    ? "d-inline-block"
                                                                    : "",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ] || "-"
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                class:
                                                                  colValues.spanClass,
                                                              },
                                                              [
                                                                colValues.label
                                                                  ? _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            colValues.label
                                                                          ) +
                                                                            ": "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                colValues.label
                                                                  ? _c("br")
                                                                  : _vm._e(),
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item[
                                                                      colValues
                                                                        .value
                                                                    ] || "-"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                      ]
                                                    ),
                                                  ]
                                                : [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        key: index,
                                                        class: colValues.class,
                                                        attrs: {
                                                          cols: colValues.cols,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            class:
                                                              colValues.rowClass,
                                                          },
                                                          [
                                                            _vm._l(
                                                              colValues.subItems,
                                                              function (
                                                                subitems,
                                                                i
                                                              ) {
                                                                return [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      key: i,
                                                                      class:
                                                                        subitems.class,
                                                                      attrs: {
                                                                        cols: subitems.cols,
                                                                      },
                                                                    },
                                                                    [
                                                                      subitems.value ===
                                                                      "is_completed"
                                                                        ? [
                                                                            _vm
                                                                              .payload
                                                                              .moduleTitle ===
                                                                            "message.layout.drawing"
                                                                              ? _c(
                                                                                  "v-select",
                                                                                  {
                                                                                    staticClass:
                                                                                      "px-0 pr-2",
                                                                                    attrs:
                                                                                      {
                                                                                        dense:
                                                                                          "",
                                                                                        "hide-details":
                                                                                          "",
                                                                                        "item-text":
                                                                                          "text",
                                                                                        "item-value":
                                                                                          "value",
                                                                                        items:
                                                                                          subitems.items,
                                                                                        label:
                                                                                          "Status",
                                                                                        outlined:
                                                                                          "",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          $event.stopPropagation()
                                                                                        },
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          _vm.saveStatusUpdate(
                                                                                            _vm.payload.list.indexOf(
                                                                                              item
                                                                                            )
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          item[
                                                                                            subitems
                                                                                              .value
                                                                                          ],
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              item,
                                                                                              subitems.value,
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "item[subitems.value]",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : [
                                                                                  !_vm
                                                                                    .payload
                                                                                    .itemHeaders
                                                                                    ? _c(
                                                                                        "span",
                                                                                        {
                                                                                          class:
                                                                                            subitems.spanClass,
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "strong",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "message.projects.status"
                                                                                                  )
                                                                                                ) +
                                                                                                  ":"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  !_vm
                                                                                    .payload
                                                                                    .itemHeaders
                                                                                    ? _c(
                                                                                        "br"
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      class:
                                                                                        subitems.spanClass,
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item[
                                                                                            subitems
                                                                                              .value
                                                                                          ] ===
                                                                                            1
                                                                                            ? _vm.$t(
                                                                                                "message.customModule.completed"
                                                                                              )
                                                                                            : _vm.$t(
                                                                                                "message.customModule.notCompleted"
                                                                                              )
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                          ]
                                                                        : subitems.isTranslatable
                                                                        ? [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                class:
                                                                                  subitems.spanClass,
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      item[
                                                                                        subitems
                                                                                          .value
                                                                                      ]
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        : _c(
                                                                            "span",
                                                                            {
                                                                              class:
                                                                                subitems.spanClass,
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    item[
                                                                                      subitems
                                                                                        .value
                                                                                    ]
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ],
                                                                    2
                                                                  ),
                                                                ]
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                            ]
                                          }
                                        ),
                                      ],
                                      _vm.payload.actionSize
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0 pr-1 mt-3",
                                              attrs: {
                                                cols: _vm.payload.actionSize,
                                              },
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  staticClass: "mx-0",
                                                  attrs: {
                                                    row: "",
                                                    wrap: "",
                                                    "justify-end": "",
                                                  },
                                                },
                                                [
                                                  !_vm.payload.isChecklist &&
                                                  _vm.payload.hasDelete &&
                                                  _vm.checkActionPermission(
                                                    _vm.payload.module,
                                                    _vm.CONSTANTS.DELETE
                                                  )
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            "max-height": "24",
                                                            "max-width": "24",
                                                            icon: "",
                                                            color: "error",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.deleteRecord(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-delete-circle"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    }
                  : {
                      key: "default",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-card",
                            {
                              staticClass: "pa-0 mt-2 my-12 overflow-x-hidden",
                              attrs: {
                                outlined: "",
                                "min-height":
                                  _vm.id === "chemicallistScrollList"
                                    ? "120"
                                    : _vm.id === "deviationScrollList"
                                    ? "160"
                                    : "90",
                                color: "#e8eaf6",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.checkActionPermission(
                                    _vm.payload.module,
                                    _vm.CONSTANTS.EDIT
                                  )
                                    ? _vm.payload.editHandler(item)
                                    : ""
                                },
                              },
                            },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "pa-0 px-2 my-2" },
                                [
                                  _vm._l(
                                    _vm.payload.items,
                                    function (colValues, index) {
                                      return [
                                        _c(
                                          "v-row",
                                          {
                                            key: index,
                                            staticClass:
                                              "pt-1 mt-0 align-center",
                                          },
                                          [
                                            _vm.payload.itemHeaders &&
                                            _vm.payload.itemHeaders.length
                                              ? _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-0",
                                                    attrs: { cols: 12 },
                                                  },
                                                  [
                                                    _vm.payload.itemHeaders[
                                                      index
                                                    ] ===
                                                      "message.projects.drawingGroup" &&
                                                    _vm.payload
                                                      .advanceDrawingTypes
                                                      ? _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                _vm.payload
                                                                  .itemHeaders[
                                                                  index
                                                                ]
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm.payload.itemHeaders[
                                                          index
                                                        ] !==
                                                        "message.projects.drawingGroup"
                                                      ? _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                _vm.payload
                                                                  .itemHeaders[
                                                                  index
                                                                ]
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "pa-0",
                                                class:
                                                  _vm.$t(
                                                    _vm.payload.moduleTitle
                                                  ) ===
                                                    _vm.$t(
                                                      "message.layout.drawing"
                                                    ) &&
                                                  item &&
                                                  item.is_completed === 5
                                                    ? "orange--text text--lighten-2"
                                                    : "",
                                                attrs: { cols: 12 },
                                              },
                                              [
                                                colValues.value
                                                  ? [
                                                      colValues.isParagraph
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mx-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item[
                                                                      colValues
                                                                        .value
                                                                    ] || "-"
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mx-2",
                                                              class:
                                                                colValues.spanClass,
                                                            },
                                                            [
                                                              colValues.label
                                                                ? _c("strong", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        colValues.label
                                                                      ) + ": "
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              colValues.label
                                                                ? _c("br")
                                                                : _vm._e(),
                                                              _vm._v(
                                                                _vm._s(
                                                                  item[
                                                                    colValues
                                                                      .value
                                                                  ] || "-"
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  : [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          class:
                                                            colValues.rowClass,
                                                        },
                                                        [
                                                          _vm._l(
                                                            colValues.subItems,
                                                            function (
                                                              subitems,
                                                              i
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    key: i,
                                                                    class:
                                                                      subitems.class,
                                                                    attrs: {
                                                                      cols: subitems.cols,
                                                                    },
                                                                  },
                                                                  [
                                                                    subitems.value ===
                                                                    "is_completed"
                                                                      ? [
                                                                          _vm
                                                                            .payload
                                                                            .moduleTitle ===
                                                                          "message.layout.drawing"
                                                                            ? _c(
                                                                                "v-select",
                                                                                {
                                                                                  staticClass:
                                                                                    "px-0 pr-2",
                                                                                  attrs:
                                                                                    {
                                                                                      dense:
                                                                                        "",
                                                                                      "hide-details":
                                                                                        "",
                                                                                      "item-text":
                                                                                        "text",
                                                                                      "item-value":
                                                                                        "value",
                                                                                      items:
                                                                                        subitems.items,
                                                                                      label:
                                                                                        "Status",
                                                                                      outlined:
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        $event.stopPropagation()
                                                                                      },
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.saveStatusUpdate(
                                                                                          _vm.payload.list.indexOf(
                                                                                            item
                                                                                          )
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ],
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item,
                                                                                            subitems.value,
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "item[subitems.value]",
                                                                                    },
                                                                                }
                                                                              )
                                                                            : [
                                                                                !_vm
                                                                                  .payload
                                                                                  .itemHeaders
                                                                                  ? _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mx-2",
                                                                                        class:
                                                                                          subitems.spanClass,
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "strong",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "message.projects.status"
                                                                                                )
                                                                                              ) +
                                                                                                ":"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                !_vm
                                                                                  .payload
                                                                                  .itemHeaders
                                                                                  ? _c(
                                                                                      "br"
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mx-2",
                                                                                    class:
                                                                                      subitems.spanClass,
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ] ===
                                                                                          1
                                                                                          ? _vm.$t(
                                                                                              "message.customModule.completed"
                                                                                            )
                                                                                          : _vm.$t(
                                                                                              "message.customModule.notCompleted"
                                                                                            )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                        ]
                                                                      : subitems.isTranslatable
                                                                      ? [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "mx-2",
                                                                              class:
                                                                                subitems.spanClass,
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    item[
                                                                                      subitems
                                                                                        .value
                                                                                    ]
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      : _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "mx-2",
                                                                            class:
                                                                              subitems.spanClass,
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  item[
                                                                                    subitems
                                                                                      .value
                                                                                  ]
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    }
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass: "mx-0 mr-5",
                                          attrs: {
                                            row: "",
                                            wrap: "",
                                            "justify-end": "",
                                          },
                                        },
                                        [
                                          !_vm.payload.isChecklist &&
                                          _vm.payload.hasDelete &&
                                          _vm.checkActionPermission(
                                            _vm.payload.module,
                                            _vm.CONSTANTS.DELETE
                                          )
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    color: "error",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.deleteRecord(
                                                        item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { large: "" } },
                                                    [
                                                      _vm._v(
                                                        "mdi-delete-circle"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
              ],
              null,
              true
            ),
          }),
          _c(
            "p",
            { staticClass: "text-center px-5 py-0" },
            [
              _vm.payload.loading
                ? _c("v-progress-linear", {
                    attrs: {
                      color: "deep-purple accent-4",
                      indeterminate: "",
                      rounded: "",
                      height: "6",
                    },
                  })
                : _vm._e(),
              false
                ? _c("v-progress-circular", {
                    staticClass: "mx-auto",
                    attrs: { indeterminate: "", color: "primary" },
                  })
                : _vm._e(),
            ],
            1
          ),
          !_vm.payload.loading && _vm.payload.list.length === 0
            ? _c(
                "v-alert",
                {
                  staticClass: "mt-2",
                  attrs: { prominent: "", text: "", type: "info" },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow" }, [
                        _vm._v(_vm._s(_vm.$t("message.common.noResults"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }